export default {
    "logo": {
        "name": "ТИК Так",
        "msg": "Tiktok Order Center",
        "wz": "URL:"
    },
    "head": {
        "Language": "Русский"
    },
    "menu": {
        "home": "титульная страница",
        "order": "Заказ",
        "trading": "торговля",
        "earnings": "команда",
        "mine": "пользователь"
    },
    "back": {
        "appDownload": "Приложение скачать",
        "help": "помощь",
        "order": "Заказ",
        "trading": "торговля",
        "income": "команда",
        "revenueDetails": "Деталь",
        "Withdrawal": "отзывать",
        "Recharge": "пополнить",
        "Invitefriends": "пригласить друзей",
        "personalinformation": "персональная информация",
        "gywm": "о нас",
        "kf": "обслуживание клиентов"
    },
    "loading": {
        "loadingText": "загрузка ...",
        "finishedText": "Больше не надо",
        "loadingText2": "Соответствует ...",
        "loadingLogin": "Вход в систему...",
        "loadingSign": "Регистр ...",
        "confirmOrder": "в транзакции ...",
        "ldg1": "В уходе ..."
    },
    "Toast": {
        "copy": "Репликация",
        "ts": "Пожалуйста, выберите язык"
    },
    "login": {
        "title": "Привет",
        "msg": "Войдите в свой аккаунт",
        "phone": "счет",
        "phoneCode": "Регион код",
        "password": "пароль",
        "remember": "запомнить меня",
        "forget": "Забудьте пароль?",
        "loginBtn": "Авторизоваться",
        "noAccount": "Нет аккаунта?",
        "goRegister": "регистр",
        "lg1": "Другая учетная запись входит в систему"
    },
    "sign": {
        "phone": "Номер телефона",
        "phoneCode": "Регион код",
        "Nickname": "Прозвище",
        "code": "Проверочный код",
        "sendCode": "Отправить код проверки",
        "password": "пароль",
        "confirmPassword": "Подтвердите пароль",
        "recommendationCode": "Рекомендательный код",
        "protocol": "Регистрация означает, что вы согласны с нашими условиями",
        "registerBtn": "регистр",
        "Existing": "Существующий аккаунт?",
        "goLogin": "Авторизоваться",
        "rule": {
            "phone": "Учетная запись не может быть пустой, формат учетной записи неверен",
            "nickname": "Прозвище должно быть заполнено",
            "password": "Пароль не может быть пустым, пароль минимален 6 цифр, а пароль не соответствует.",
            "code": "Код проверки должен быть заполнен",
            "isAgree": "Пожалуйста, сначала проверьте согласие"
        }
    },
    "home": {
        "cumulativeIncome": "Совокупный доход",
        "notification": "поставить в известность",
        "moduleTitle1": "Мой сервис",
        "moduleTitle2": "Партнер",
        "title": "поставить в известность"
    },
    "download": {
        "TIKTOKSHOP": "Tiktok Store",
        "AndroidDownload": "Android скачать",
        "iOSDownload": "Apple скачать"
    },
    "help": {
        "bz2": "Общая проблема",
        "bz3": "Торговые правила",
        "bz4": "Как снять деньги",
        "bz5": "Как перезарядиться",
        "bz6": "1. Что такое istiktokshop?",
        "bz7": "Tiktokshop - компания по продвижению маркетинга, которая помогает торговцам интернет -магазина Tiktokshop со всего мира, чтобы получить больше продаж заказа и увеличить данные просмотра в интернет -магазинах Tiktokshop. Мы стремимся создать трехпартийную модель маркетинга прибыли между Tiktokshop и торговцами и потребителями. Объединяя новейшую технологию блокчейна P2P, быстро подключают потребителей и продавцов через USDT (TRC20, ERC20). Зарегистрированные пользователи могут получить комиссию за заказы, в то время как торговцы могут увеличить данные о продажах магазина. Это последняя модель прибыли в модели интернет -блокчейна!",
        "bz8": "2. Как работать Ontiktokshop?",
        "bz9": "Благодаря ежедневной обратной связи Tiktokshop, продукты, которые необходимо улучшить в продажах Tiktokshop, должны быть улучшены. Пользователи платят сумму заказа через блокчейн USDT и получают ежедневные комиссионные.",
        "bz10": "3. Почему существует разница в цене одной и той же валюты в двух транзакциях?",
        "bz11": "Ценовые различия вызваны всеми факторами, которые не способствуют свободе валюты, включая скорость передачи валюты, условия сети, ограничения доступа валюты, люди в разных регионах, даже типы торгов и транзакции, предоставляемые обменом. Следовательно, одна и та же валюта может вызвать цены различий в различных транзакциях.",
        "bz12": "4. Инвестиционная прибыль?",
        "bz13": "Чем выше цена, тем выше, тем выше прибыль. В то же время Tiktokshop случайным образом выделяет крупные приказы комиссии.",
        "bz14": "Каждый день система автоматически генерирует и распространяет 60 заказов на товар для пользователей. Крупные комиссионные заказы распределены.",
        "bz15": "USDT может быть снят после 60 заказов. (Если 60 заказов не будут выполнены, система автоматически остановится до момента избыточного заказа дня)",
        "bz16": "Каждая учетная запись может быть связана только с учетной записью USDT.",
        "bz17": "После завершения ежедневной доставки, вы можете снять нормально.",
        "bz18": "Перед перезарядкой, пожалуйста, связывайте свой адрес снятия (поддержка TRC-20) USDT Адрес.",
  "bz19": "Существуют запрещенные поведения, такие как взаимная перезарядка или банковские переводы, и обменные переводы между соответствующими учетными записями."

    },
    "order": {
        "incomplete": "отменен",
        "completed": "завершенный",
        "orderNumber": "номер заказа",
        "transactionTime": "час транзакции",
        "price": "Количество",
        "expectedReturns": "Ожидаемое возвращение",
        "submit": "Подтвердить заказ"
    },
    "trading": {
        "accountBalance": "Баланс",
        "introduce": "Введение транзакции",
        "freeze": "Заполнен номер",
        "walletBalance": "Общая задача",
        "startMatching": "Начните сопоставить",
        "yield": "Сегодняшние преимущества",
        "orderNumber": "номер заказа",
        "transactionTime": "час транзакции",
        "price": "Количество",
        "expectedReturns": "Ожидаемое возвращение",
        "startTrading": "Начните торговать",
        "cancel": "Отмена",
        "ts": "Недостаточный баланс, все еще бедный",
        "msg": "Tiktokshop использует обратную связь от Tiktokshop каждый день для отображения продуктов, которые необходимо улучшить для продаж Tiktokshop. Если пользователю нужно только щелкнуть по заказу, система автоматически генерирует подпись заказа. Пользователь выплачивает сумму заказа через блокчейн USDT и может каждый раз получать более 0,6%комиссии, а система случайным образом распределяет большие заказы вознаграждения.",
        "ts2": "намекать",
        "ts3": "Недостаточный баланс",
        "ts4": "Перейти к перезарядке",
        "ts5": "Отмена"
    },
    "income": {
        "totalRevenue": "Баланс",
        "residualIncome": "комиссия",
        "claim": "получать",
        "moduleTitle": "моя команда",
        "headcount": "Всего людей",
        "totalRecharge": "Полная перезарядка",
        "grade": "пользователь",
        "numberOfPeople": "Вклад (количество)",
        "income": "состояние",
        "viewDetails": "Посмотреть детали",
        "task_num": "отменен",
        "task_num2": "завершенный"
    },
    "revenueDetails": {
        "totalRevenue": "Общий доход",
        "headcount": "Всего людей",
        "totalRecharge": "Полная перезарядка",
        "userLevel1": "Пользовательский уровень 1",
        "userLevel2": "Пользовательский уровень 2",
        "userLevel3": "Пользовательский уровень 3",
        "Nickname": "Прозвище",
        "phone": "Номер телефона",
        "Recharge": "пополнить",
        "income": "доход",
        "date": "Время регистрации"
    },
    "user": {
        "Myaccount": "мой счет",
        "Withdrawal": "отзывать",
        "Recharge": "пополнить",
        "InviteFriend": "Пригласить друзей",
        "InviteFriendTxt": "За каждую прибыль, полученную друзьями, вы получите соответствующую комиссию по долю",
        "InviteFriendBtn": "Пригласить сейчас",
        "MyInformation": "персональная информация",
        "FundsDetails": "Детали капитала",
        "RechargeRecord": "Зарядка записи",
        "WithdrawalRecord": "Запись о снятии",
        "Language": "язык",
        "Logout": "выход"
    },
    "Withdrawal": {
        "address": "Адрес отмены",
        "placeholder1": "Адрес отмены",
        "availableBalance": "доступные средства",
        "placeholder2": "Сумма снятия",
        "actualBalance": "На самом деле",
        "placeholder3": "На самом деле",
        "password": "пароль",
        "placeholder4": "Пожалуйста, введите 6-16-битный пароль",
        "submit": "представлять на рассмотрение",
        "amount": "Не больше, чем доступные суммы",
        "w1": "Плата за обработку",
        "ts1": "Пожалуйста, получите адрес вывода",
        "ts2": "Пожалуйста, введите сумму отмены"
    },
    "Recharge": {
        "qrcode": "Пополнение кода сканирования",
        "copy": "Нажмите, чтобы скопировать",
        "cotakecarepy": "Примечание, связанное соображение - использовать свой экран или страницу недвижимости",
        "ts1": "Перезарядка сумма",
        "ts2": "Другая сумма",
        "ts3": "Конечно",
        "ts4": "Пожалуйста, сначала подключите кошелек",
        "ts5": "Пожалуйста, выберите сумму перезарядки",
        "ts6": "В перезарядке ..."
    },
    "Invitefriends": {
        "inviteCode": "Код приглашения",
        "inviteTxt": "Пригласить друзей, насладиться большим количеством доходов, пригласите",
        "copy": "Нажмите, чтобы скопировать",
        "Rebaterules": "Скидка",
        "RebaterulesMsg": "Следующие условия должны быть выполнены",
        "scale": "Соотношение правил комиссии следующим образом",
        "Userlevel": "пользовательский уровень",
        "Rules": "правило",
        "Commissionratio": "ставка комиссии",
        "ps": "Комиссия урегулирована с USDT, который может быть снят на ваш кошелек в любое время"
    },
    "personalinformation": {
        "Nickname": "Прозвище",
        "phone": "счет",
        "Loginpassword": "логин Пароль",
        "address": "Адрес отмены",
        "modify": "Исправлять",
        "Confirm": "Конечно"
    },
    "fundDetails": {
        "amount": "Количество",
        "revenue": "доход",
        "time": "время",
        "withdrawal": "отзывать",
        "recharge": "пополнить",
        "type": {
    "1": "пополнить",
    "2": "отзывать",
    "3": "Компитация коллекции",
    "4": "доход",
    "5": "Отзывать",
    "6": "В ожидании",
    "7": "завершенный",
    "8": "отклоненный"
  }
    },
    "language": {
        "placeholder": "Пожалуйста, введите код международного района",
        "Confirm": "Конечно",
        "cancel": "Отмена"
    },
    "request": {
        "loadingText": "загрузка ...",
        "Serverexception": "Серверные аномалии"
    },
    "newLang": { "a1": "Баланс плохой", "a2": "Текущий номер задачи","a3": "Пожалуйста, сначала перейдите по адресу об отмене","a4": "Недостаточный баланс", "a5": "Подзарядиться" },
    "newLang2": {
        "a1": "Для привязки, пожалуйста, свяжитесь с обслуживанием клиентов, чтобы изменить",
        "a2": "Нужно перезарядиться",
        "a3": "Пожалуйста, свяжитесь с службой поддержки, чтобы изменить пароль",
         "a33":"Póngase"
      }
}