export default {
  "logo": { "name": "Tik의 톡", "msg": "Tiktok 주문 센터", "wz": "URL :" },
  "head": { "Language": "한국인" },
  "menu": {
    "home": "첫 장",
    "order": "주문하다",
    "trading": "거래",
    "earnings": "팀",
    "mine": "사용자"
  },
  "back": {
    "appDownload": "앱 다운로드",
    "help": "돕다",
    "order": "주문하다",
    "trading": "거래",
    "income": "팀",
    "revenueDetails": "세부 사항",
    "Withdrawal": "철회하다",
    "Recharge": "위로",
    "Invitefriends": "친구를 초대",
    "personalinformation": "개인 정보",
    "gywm": "우리에 대해",
    "kf": "고객 서비스"
  },
  "loading": {
    "loadingText": "로딩 ...",
    "finishedText": "더 이상은 없어",
    "loadingText2": "일치하는 ...",
    "loadingLogin": "로그인 ...",
    "loadingSign": "등록하다 ...",
    "confirmOrder": "거래에서 ...",
    "ldg1": "철수에서 ..."
  },
  "Toast": { "copy": "복제", "ts": "언어를 선택하십시오" },
  "login": {
    "title": "안녕하세요",
    "msg": "계정에 로그인하십시오",
    "phone": "계정",
    "phoneCode": "지역 코드",
    "password": "비밀번호",
    "remember": "날 기억해",
    "forget": "비밀번호를 잊어 버리시겠습니까?",
    "loginBtn": "로그인",
    "noAccount": "계정이 없습니까?",
    "goRegister": "등록하다",
    "lg1": "다른 계정 로그인"
  },
  "sign": {
    "phone": "전화 번호",
    "phoneCode": "지역 코드",
    "Nickname": "별명",
    "code": "확인 코드",
    "sendCode": "확인 코드를 보냅니다",
    "password": "비밀번호",
    "confirmPassword": "비밀번호 확인",
    "recommendationCode": "추천 코드",
    "protocol": "등록은 귀하가 당사 이용 약관에 동의 함을 의미합니다",
    "registerBtn": "등록하다",
    "Existing": "기존 계정?",
    "goLogin": "로그인",
    "rule": {
      "phone": "계정은 비어있을 수없고 계정 형식이 잘못되었습니다.",
      "nickname": "별명을 채워야합니다",
      "password": "비밀번호는 비어있을 수없고 암호는 최소 6 자리이며 암호는 비 암호로 확인할 수 없습니다",
      "code": "확인 코드를 채워야합니다",
      "isAgree": "먼저 동의를 확인하십시오"
    }
  },
  "home": {
    "cumulativeIncome": "누적 수입",
    "notification": "알림",
    "moduleTitle1": "내 서비스",
    "moduleTitle2": "파트너",
    "title": "알림"
  },
  "download": {
    "TIKTOKSHOP": "Tiktok 상점",
    "AndroidDownload": "안드로이드 다운로드",
    "iOSDownload": "애플 다운로드"
  },
  "help": {
    "bz2": "일반적인 문제",
    "bz3": "거래 규칙",
    "bz4": "돈을 인출하는 방법",
    "bz5": "재충전하는 방법",
    "bz6": "1. istiktokshop은 무엇입니까?",
    "bz7": "Tiktokshop은 전 세계의 Tiktokshop 온라인 상점 판매자가 더 많은 주문 판매를 받고 Tiktokshop Online Stores에서 시청 데이터를 늘릴 수 있도록 돕는 마케팅 프로모션 회사입니다. 우리는 Tiktokshop과 상인과 소비자 사이에 3 개의 파티 이익 마케팅 모델을 구축하기 위해 노력하고 있습니다. 최신 P2P 블록 체인 기술을 결합하여 USDT (TRC20, ERC20)를 통해 소비자와 판매자를 빠르게 연결하십시오. 등록 된 사용자는 주문 수수료를받을 수있는 반면 판매자는 상점의 판매 데이터를 늘릴 수 있습니다. 인터넷 블록 체인 모델의 최신 이익 모델입니다!",
    "bz8": "2. Ontiktokshop에서 일하는 방법?",
    "bz9": "Tiktokshop의 일일 피드백을 통해 Tiktokshop 판매에서 개선 해야하는 제품은 주문 구독을 자동으로 생성하려면 주문을 쉽게 클릭하면됩니다. 사용자는 블록 체인 USDT를 통해 주문 금액을 지불하고 매일 커미션을받습니다.",
    "bz10": "3. 두 거래에서 동일한 통화 가격에 차이가있는 이유는 무엇입니까?",
    "bz11": "가격 차이는 통화 전송 속도, 네트워크 조건, 통화 액세스 제한 제한, 다른 지역의 사람, 심지어 거래소가 제공하는 거래 유형 및 거래를 포함하여 통화의 자유에 도움이되지 않는 모든 요소로 인해 발생합니다. 따라서 동일한 통화로 인해 다른 거래에서 가격 차이가 발생할 수 있습니다.",
    "bz12": "4. 투자 이익?",
    "bz13": "가격이 높을수록 이익이 높아집니다. 동시에 Tiktokshop은 무작위로 큰 커미션 명령을 할당합니다.",
    "bz14": "매일 시스템은 사용자에게 60 개의 상품 주문을 자동으로 생성하고 배포합니다. 큰 커미션 명령은 무작위로 배포됩니다.",
    "bz15": "60 주문 후에 USDT를 철회 할 수 있습니다. (60 개의 주문이 완료되지 않은 경우 시스템은 하루의 잉여 주문 금액이 자동으로 중지됩니다).",
    "bz16": "각 계정은 오류가 발생하면 고객 서비스에 문의하십시오",
    "bz17": "일일 주문 배달을 완료하면 철수를 시작한 후 24 시간 이내에 계정을받을 수 있으며 철회에 대한 제한이 없습니다.",
    "bz18": "재충전하기 전에 인출 주소 (지원 TRC-20) USDT 주소를 바인딩하십시오. 앱 또는 웹 페이지의 재충전을 클릭하고 재충전에 사용하는 블록 체인 (TRC-20)을 선택하십시오.",
    "bz19": "상호 재충전 또는 은행 전송과 같은 금지 된 행동이 있으며, 관련 계정 간의 교환 전송이 악의적으로 잡는 것으로 의심되며, 그 행동은 계정 번호에 의해 처벌 될 것입니다."

  },
  "order": {
    "incomplete": "푼",
    "completed": "완전한",
    "orderNumber": "주문 번호",
    "transactionTime": "거래 시간",
    "price": "양",
    "expectedReturns": "예상 수익",
    "submit": "주문을 제출"
  },
  "trading": {
    "accountBalance": "계정 잔액",
    "introduce": "거래 소개",
    "freeze": "완료된 번호",
    "walletBalance": "총 작업",
    "startMatching": "일치 시작하십시오",
    "yield": "오늘의 혜택",
    "orderNumber": "주문 번호",
    "transactionTime": "거래 시간",
    "price": "양",
    "expectedReturns": "예상 수익",
    "startTrading": "거래를 시작하십시오",
    "cancel": "취소",
    "ts": "균형이 불충분하고 여전히 가난합니다",
    "msg": "Tiktokshop은 매일 Tiktokshop의 피드백을 사용하여 Tiktokshop 판매를 위해 개선 해야하는 제품을 표시합니다. 사용자가 주문 만 클릭하면 시스템이 자동으로 주문 서명을 생성합니다. 사용자는 USDT 블록 체인을 통해 주문 금액을 지불하고 매번 커미션의 0.6%이상을 얻을 수 있으며 시스템은 큰 보상 주문을 무작위로 배포합니다.",
    "ts2": "힌트",
    "ts3": "잔액 불충분",
    "ts4": "재충전으로 가십시오",
    "ts5": "취소"
  },
  "income": {
    "totalRevenue": "균형",
    "residualIncome": "수수료",
    "claim": "받다",
    "moduleTitle": "내 팀",
    "headcount": "총 사람들",
    "totalRecharge": "총 재충전",
    "grade": "사용자",
    "numberOfPeople": "기여 (수량)",
    "income": "상태",
    "viewDetails": "세부 정보보기",
    "task_num": "푼",
    "task_num2": "완전한"
  },
  "revenueDetails": {
    "totalRevenue": "총 수익",
    "headcount": "총 사람들",
    "totalRecharge": "총 재충전",
    "userLevel1": "사용자 수준 1",
    "userLevel2": "사용자 수준 2",
    "userLevel3": "사용자 수준 3",
    "Nickname": "별명",
    "phone": "전화 번호",
    "Recharge": "위로",
    "income": "소득",
    "date": "등록 시간"
  },
  "user": {
    "Myaccount": "내 계정",
    "Withdrawal": "철회하다",
    "Recharge": "위로",
    "InviteFriend": "친구를 초대하십시오",
    "InviteFriendTxt": "친구가 얻은 모든 이익에 대해 해당 비율 커미션을 받게됩니다.",
    "InviteFriendBtn": "지금 초대하십시오",
    "MyInformation": "개인 정보",
    "FundsDetails": "자본 세부 사항",
    "RechargeRecord": "재충전 기록",
    "WithdrawalRecord": "인출 기록",
    "Language": "언어",
    "Logout": "로그 아웃하십시오"
  },
  "Withdrawal": {
    "address": "철수 주소",
    "placeholder1": "철수 주소",
    "availableBalance": "사용 가능한 잔액",
    "placeholder2": "철수 금액",
    "actualBalance": "실제로",
    "placeholder3": "실제로",
    "password": "비밀번호",
    "placeholder4": "6-16 비트 비밀번호를 입력하십시오",
    "submit": "제출하다",
    "amount": "사용 가능한 금액보다 크지 마십시오",
    "w1": "수수료",
    "ts1": "인출 주소를 받으십시오",
    "ts2": "인출 금액을 입력하십시오"
  },
  "Recharge": {
    "qrcode": "스캔 코드 충전",
    "copy": "복사하려면 클릭하십시오",
    "cotakecarepy": "관련 고려 사항은 화면 또는 페이지 부동산을 사용하는 것입니다.",
    "ts1": "재충전 금액",
    "ts2": "기타 금액",
    "ts3": "확신하는",
    "ts4": "먼저 지갑을 연결하십시오",
    "ts5": "재충전 금액을 선택하십시오",
    "ts6": "재충전 ..."
  },
  "Invitefriends": {
    "inviteCode": "초대 코드",
    "inviteTxt": "친구를 초대하고, 많은 수입을 즐기고, 초대하십시오",
    "copy": "복사하려면 클릭하십시오",
    "Rebaterules": "할인",
    "RebaterulesMsg": "다음 조건을 충족해야합니다",
    "scale": "커미션 비율의 규칙은 다음과 같습니다",
    "Userlevel": "사용자 수준",
    "Rules": "규칙",
    "Commissionratio": "커미션 비율",
    "ps": "위원회는 USDT로 정착되며 언제든지 지갑으로 철회 할 수 있습니다."
  },
  "personalinformation": {
    "Nickname": "별명",
    "phone": "계정",
    "Loginpassword": "로그인 비밀번호",
    "address": "철수 주소",
    "modify": "개정하다",
    "Confirm": "확신하는"
  },
  "fundDetails": {
    "amount": "양",
    "revenue": "소득",
    "time": "시간",
    "withdrawal": "철회하다",
    "recharge": "위로",
    "type": {
      "1": "위로",
      "2": "철회하다",
      "3": "커미션 컬렉션",
      "4": "소득",
      "5": "철회하다",
      "6": "보류 중",
      "7": "완전한",
      "8": "거부"
    }
  },
  "language": {
    "placeholder": "국제 지역 코드를 입력하십시오",
    "Confirm": "확신하는",
    "cancel": "취소"
  },
  "request": { "loadingText": "로딩 ...", "Serverexception": "서버 이상" },
  "newLang": { "a1": "균형이 좋지 않습니다", "a2": "현재 작업 번호", "a3": "바인딩 철수 주소로 먼저 이동하십시오", "a4": "잔액 불충분", "a5": "재충전 여부" },
  "newLang2": {
    "a1": "바인딩 주소는 고객 서비스에 문의하여 수정하십시오.",
    "a2": "재충전해야합니다",
    "a3": "비밀번호를 수정하려면 고객 서비스에 문의하십시오",
    "a33": "Póngase"
  }
}
