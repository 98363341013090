export default {
  "logo": { "name": "Tic toc", "msg": "Centro ordini tiktok", "wz": "URL:" },
  "head": { "Language": "Italia" },
  "menu": {
    "home": "prima pagina",
    "order": "Ordine",
    "trading": "commercio",
    "earnings": "squadra",
    "mine": "utente"
  },
  "back": {
    "appDownload": "Download dell'app",
    "help": "aiuto",
    "order": "Ordine",
    "trading": "commercio",
    "income": "squadra",
    "revenueDetails": "Dettaglio",
    "Withdrawal": "ritirare",
    "Recharge": "Riempire",
    "Invitefriends": "invita gli amici",
    "personalinformation": "informazione personale",
    "gywm": "chi siamo",
    "kf": "assistenza clienti"
  },
  "loading": {
    "loadingText": "caricamento...",
    "finishedText": "Non più",
    "loadingText2": "Abbinamento ...",
    "loadingLogin": "entrando...",
    "loadingSign": "Registrati ...",
    "confirmOrder": "Nella transazione ...",
    "ldg1": "Nel ritiro ..."
  },
  "Toast": { "copy": "Replica", "ts": "Seleziona il linguaggio" },
  "login": {
    "title": "Ciao",
    "msg": "Accedi al tuo account",
    "phone": "account",
    "phoneCode": "Codice della regione",
    "password": "parola d'ordine",
    "remember": "Ricordati di me",
    "forget": "Dimentica la password?",
    "loginBtn": "Login",
    "noAccount": "Nessun account?",
    "goRegister": "Registrati",
    "lg1": "Altro accedere all'account"
  },
  "sign": {
    "phone": "Numero di telefono",
    "phoneCode": "Codice della regione",
    "Nickname": "Piccolo",
    "code": "Codice di verifica",
    "sendCode": "Invia il codice di verifica",
    "password": "parola d'ordine",
    "confirmPassword": "Conferma password",
    "recommendationCode": "Codice di raccomandazione",
    "protocol": "Registrati significa che sei d'accordo con i nostri termini e condizioni",
    "registerBtn": "Registrati",
    "Existing": "Account esistente?",
    "goLogin": "Login",
    "rule": {
      "phone": "L'account non può essere vuoto, il formato dell'account non è corretto",
      "nickname": "Il soprannome dovrebbe essere riempito",
      "password": "La password non può essere vuota, la password è minima a 6 cifre e la password è incoerente.",
      "code": "Il codice di verifica deve essere riempito",
      "isAgree": "Si prega di controllare prima il consenso"
    }
  },
  "home": {
    "cumulativeIncome": "Reddito cumulativo",
    "notification": "notificare",
    "moduleTitle1": "Il mio servizio",
    "moduleTitle2": "Compagno",
    "title": "notificare"
  },
  "download": {
    "TIKTOKSHOP": "Negozio tiktok",
    "AndroidDownload": "Download Android",
    "iOSDownload": "Download di Apple"
  },
  "help": {
    "bz2": "problema comune",
    "bz3": "Regole di trading",
    "bz4": "Come prelevare denaro",
    "bz5": "Come ricaricare",
    "bz6": "1. Cosa istaktokshop?",
    "bz7": "Tiktokshop è una società di promozione del marketing che aiuta i commercianti di tiktokshop online di tutto il mondo per ottenere più vendite di ordini e aumentare i dati di visualizzazione dai negozi online di Tiktokshop. Ci impegniamo a stabilire un modello di marketing di profitti a tre parti tra tiktokshop e commercianti e consumatori. Combinando la più recente tecnologia Blockchain P2P, collegano rapidamente i consumatori e i commercianti tramite USDT (TRC20, ERC20). Gli utenti registrati possono ottenere la commissione degli ordini, mentre i commercianti possono aumentare i dati di vendita del negozio. È l'ultimo modello di profitto nel modello Blockchain di Internet!",
    "bz8": "2. Come lavorare ontiktokshop?",
    "bz9": "Attraverso il feedback giornaliero di Tiktokshop, i prodotti che devono essere migliorati nelle vendite di Tiktokshop devono essere migliorati. Gli utenti pagano l'importo dell'ordine attraverso la blockchain USDT e ottengono commissioni giornaliere.",
    "bz10": "3. Perché c'è una differenza nel prezzo della stessa valuta nelle due transazioni?",
    "bz11": "Le differenze di prezzo sono causate da tutti i fattori che non sono favorevoli alla libertà di valuta, compresa la velocità di trasferimento di valuta, le condizioni di rete, le restrizioni di accesso alla valuta, le persone in diverse regioni e persino i tipi di trading e le transazioni fornite dallo scambio. Pertanto, la stessa valuta può causare differenze di prezzo nelle diverse transazioni.",
    "bz12": "4. Profitto degli investimenti?",
    "bz13": "Maggiore è il prezzo che ottieni, maggiore è il profitto che ottieni. Allo stesso tempo, Tiktokshop assegna casualmente gli ordini di grandi commissioni.",
    "bz14": "Ogni giorno, il sistema genera e distribuisce automaticamente 60 ordini di materie prime all'utente. I grandi ordini di commissioni sono distribuiti in modo casuale.",
    "bz15": "L'USDT può essere ritirato dopo 60 ordini. (Se i 60 ordini non vengono completati, il sistema si fermerà automaticamente fino all'importo dell'ordine in eccesso del giorno)",
    "bz16": "Ogni account può essere associato a un account USDT.",
    "bz17": "Dopo aver completato la consegna dell'ordine giornaliero, puoi prelevare normalmente.",
    "bz18": "Prima di ricaricare, legare l'indirizzo di prelievo (supporto TRC-20) Indirizzo USDT.",
  "bz19": "Ci sono comportamenti proibiti come la ricarica reciproca o i trasferimenti di banche e i trasferimenti di scambio tra conti correlati."

  },
  "order": {
    "incomplete": "annullato",
    "completed": "completato",
    "orderNumber": "numero d'ordine",
    "transactionTime": "ora di transazione",
    "price": "Quantità",
    "expectedReturns": "Ritorno previsto",
    "submit": "Inviare ordine"
  },
  "trading": {
    "accountBalance": "Saldo del conto",
    "introduce": "Introduzione della transazione",
    "freeze": "Numero completato",
    "walletBalance": "Attività totale",
    "startMatching": "Inizia a abbinare",
    "yield": "Benefici di oggi",
    "orderNumber": "numero d'ordine",
    "transactionTime": "ora di transazione",
    "price": "Quantità",
    "expectedReturns": "Ritorno previsto",
    "startTrading": "Inizia a trading",
    "cancel": "Annulla",
    "ts": "Equilibrio insufficiente, ancora scarso",
    "msg": "Tiktokshop utilizza il feedback da Tiktokshop ogni giorno per visualizzare prodotti che devono essere migliorati per le vendite di Tiktokshop. Se l'utente deve solo fare clic sull'ordine, il sistema genererà automaticamente una firma dell'ordine. L'utente paga l'importo dell'ordine attraverso la blockchain USDT e può ottenere oltre lo 0,6%della commissione ogni volta e il sistema distribuisce casualmente ordini di ricompensa di grandi dimensioni.",
    "ts2": "suggerimento",
    "ts3": "Equilibrio insufficiente",
    "ts4": "Vai a ricaricare",
    "ts5": "Annulla"
  },
  "income": {
    "totalRevenue": "Bilancia",
    "residualIncome": "commissione",
    "claim": "ricevere",
    "moduleTitle": "la mia squadra",
    "headcount": "persone totali",
    "totalRecharge": "Ricarica totale",
    "grade": "utente",
    "numberOfPeople": "Contributo (quantità)",
    "income": "stato",
    "viewDetails": "Visualizza dettagli",
    "task_num": "annullato",
    "task_num2": "completato"
  },
  "revenueDetails": {
    "totalRevenue": "Entrate totali",
    "headcount": "persone totali",
    "totalRecharge": "Ricarica totale",
    "userLevel1": "Livello 1",
    "userLevel2": "Livello utente 2",
    "userLevel3": "Livello utente 3",
    "Nickname": "Piccolo",
    "phone": "Numero di telefono",
    "Recharge": "Riempire",
    "income": "reddito",
    "date": "Tempo di registrazione"
  },
  "user": {
    "Myaccount": "il mio conto",
    "Withdrawal": "ritirare",
    "Recharge": "Riempire",
    "InviteFriend": "Invita i tuoi amici",
    "InviteFriendTxt": "Per ogni profitto guadagnato dagli amici, otterrai la commissione proporzionale corrispondente",
    "InviteFriendBtn": "Invita ora",
    "MyInformation": "informazione personale",
    "FundsDetails": "Dettagli di capitale",
    "RechargeRecord": "Record di ricarica",
    "WithdrawalRecord": "Record di prelievi",
    "Language": "lingua",
    "Logout": "disconnessione"
  },
  "Withdrawal": {
    "address": "Indirizzo di prelievo",
    "placeholder1": "Indirizzo di prelievo",
    "availableBalance": "saldo disponibile",
    "placeholder2": "Importo di prelievo",
    "actualBalance": "In realtà",
    "placeholder3": "In realtà",
    "password": "parola d'ordine",
    "placeholder4": "Immettere la password a 6-16 bit",
    "submit": "invia",
    "amount": "Non più grandi degli importi disponibili",
    "w1": "Commissione di gestione",
    "ts1": "Si prega di ottenere l'indirizzo di prelievo",
    "ts2": "Inserisci l'importo del prelievo"
  },
  "Recharge": {
    "qrcode": "Ricarica del codice di scansione",
    "copy": "Fare clic per copiare",
    "cotakecarepy": "Nota, una considerazione correlata è quella di utilizzare la schermata o la pagina immobiliare",
    "ts1": "Importo di ricarica",
    "ts2": "Altro importo",
    "ts3": "Sicuro",
    "ts4": "Si prega di collegare prima il portafoglio",
    "ts5": "Seleziona l'importo della ricarica",
    "ts6": "In ricarica ..."
  },
  "Invitefriends": {
    "inviteCode": "Codice di invito",
    "inviteTxt": "Invita gli amici, goditi una grande quantità di reddito, per favore invita",
    "copy": "Fare clic per copiare",
    "Rebaterules": "Sconto",
    "RebaterulesMsg": "Le seguenti condizioni devono essere soddisfatte",
    "scale": "Il rapporto delle regole della commissione è il seguente",
    "Userlevel": "Livello dell'utente",
    "Rules": "regola",
    "Commissionratio": "tasso di commissione",
    "ps": "La commissione è risolta con USDT, che può essere ritirata sul tuo portafoglio in qualsiasi momento"
  },
  "personalinformation": {
    "Nickname": "Piccolo",
    "phone": "account",
    "Loginpassword": "password per il login",
    "address": "Indirizzo di prelievo",
    "modify": "Rivedere",
    "Confirm": "Sicuro"
  },
  "fundDetails": {
    "amount": "Quantità",
    "revenue": "reddito",
    "time": "tempo",
    "withdrawal": "ritirare",
    "recharge": "Riempire",
    "type": {
    "1": "Riempire",
    "2": "ritirare",
    "3": "Raccolta delle commissioni",
    "4": "reddito",
    "5": "Ritirare",
    "6": "In attesa di",
    "7": "completato",
    "8": "respinto"
  }
  },
  "language": {
    "placeholder": "Inserisci il codice di area internazionale",
    "Confirm": "Sicuro",
    "cancel": "Annulla"
  },
  "request": {
    "loadingText": "caricamento...",
    "Serverexception": "Anomalie del server"
  },
  "newLang": {
    "a1": "L'equilibrio è scarso",
    "a2": "Numero dell'attività corrente",
    "a3": "Si prega di andare prima all'indirizzo di prelievo vincolante",
    "a4": "Equilibrio insufficiente", 
    "a5": "Se ricaricare"
  },
  "newLang2": {
    "a1": "Per l'indirizzo di vincolo, contattare il servizio clienti per modificare",
    "a2": "Bisogno di ricaricare",
    "a3": "Si prega di contattare il servizio clienti per modificare la password",
    "a33":"Póngase"
  }
}
