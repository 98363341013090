export default {
  "logo": { "name": "টিক টক", "msg": "টিকটোক অর্ডার সেন্টার", "wz": "ইউআরএল:" },
  "head": { "Language": "বাংলা" },
  "menu": {
    "home": "প্রথম পৃষ্ঠা",
    "order": "আদেশ",
    "trading": "বাণিজ্য",
    "earnings": "টীম",
    "mine": "ব্যবহারকারী"
  },
  "back": {
    "appDownload": "অ্যাপ্লিকেশন ডাউনলোড",
    "help": "সাহায্য",
    "order": "আদেশ",
    "trading": "বাণিজ্য",
    "income": "টীম",
    "revenueDetails": "বিশদ",
    "Withdrawal": "প্রত্যাহার",
    "Recharge": "শীর্ষে",
    "Invitefriends": "বন্ধুদের আমন্ত্রণ জানান",
    "personalinformation": "ব্যক্তিগত তথ্য",
    "gywm": "আমাদের সম্পর্কে",
    "kf": "গ্রাহক সেবা"
  },
  "loading": {
    "loadingText": "লোড হচ্ছে ...",
    "finishedText": "আর না",
    "loadingText2": "ম্যাচিং ...",
    "loadingLogin": "লগ ইন ...",
    "loadingSign": "নিবন্ধন ...",
    "confirmOrder": "লেনদেনে ...",
    "ldg1": "প্রত্যাহার ..."
  },
  "Toast": { "copy": "প্রতিলিপি", "ts": "ভাষা নির্বাচন করুন" },
  "login": {
    "title": "হ্যালো",
    "msg": "আপনার অ্যাকাউন্টে লগ ইন করুন",
    "phone": "অ্যাকাউন্ট",
    "phoneCode": "অঞ্চল কোড",
    "password": "পাসওয়ার্ড",
    "remember": "আমাকে মনে কর",
    "forget": "পাসওয়ার্ড ভুলে যাবেন?",
    "loginBtn": "প্রবেশ করুন",
    "noAccount": "কোন অ্যাকাউন্ট?",
    "goRegister": "নিবন্ধন",
    "lg1": "অন্যান্য অ্যাকাউন্ট লগইন"
  },
  "sign": {
    "phone": "ফোন নম্বর",
    "phoneCode": "অঞ্চল কোড",
    "Nickname": "নিক নাম",
    "code": "যাচাইকরণ কোড",
    "sendCode": "যাচাইকরণ কোড প্রেরণ করুন",
    "password": "পাসওয়ার্ড",
    "confirmPassword": "পাসওয়ার্ড নিশ্চিত করুন",
    "recommendationCode": "সুপারিশ কোড",
    "protocol": "রেজিস্টার মানে আপনি আমাদের শর্তাদি এবং শর্তগুলির সাথে একমত",
    "registerBtn": "নিবন্ধন",
    "Existing": "বিদ্যমান হিসাব?",
    "goLogin": "প্রবেশ করুন",
    "rule": {
      "phone": "অ্যাকাউন্টটি খালি থাকতে পারে না, অ্যাকাউন্টের ফর্ম্যাটটি ভুল",
      "nickname": "ডাকনাম পূরণ করা উচিত",
      "password": "পাসওয়ার্ডটি খালি থাকতে পারে না, পাসওয়ার্ডটি ন্যূনতম 6 টি সংখ্যা এবং পাসওয়ার্ডটি বেমানান যে পাসওয়ার্ডটি খালি থাকতে পারে না।",
      "code": "যাচাইকরণ কোড পূরণ করতে হবে",
      "isAgree": "প্রথমে সম্মতি পরীক্ষা করুন"
    }
  },
  "home": {
    "cumulativeIncome": "ক্রমবর্ধমান আয়",
    "notification": "অবহিত",
    "moduleTitle1": "আমার পরিষেবা",
    "moduleTitle2": "অংশীদার",
    "title": "অবহিত"
  },
  "download": {
    "TIKTOKSHOP": "টিকটোক স্টোর",
    "AndroidDownload": "অ্যান্ড্রয়েড ডাউনলোড",
    "iOSDownload": "অ্যাপল ডাউনলোড"
  },
  "help": {
    "bz2": "সাধারন সমস্যা",
    "bz3": "ট্রেডিং বিধি",
    "bz4": "কিভাবে টাকা তুলতে",
    "bz5": "কিভাবে রিচার্জ করবেন",
    "bz6": "1. কি ইস্টিটকশপ?",
    "bz7": "টিকটোকশপ একটি বিপণন প্রচার সংস্থা যা টিকটোকশপ অনলাইন স্টোর বণিকদের সারা বিশ্ব থেকে আরও অর্ডার বিক্রয় পেতে এবং টিকটোকশপ অনলাইন স্টোর থেকে দেখার ডেটা বাড়াতে সহায়তা করে। আমরা টিকটোকশপ এবং বণিক এবং ভোক্তাদের মধ্যে একটি তিন -পার্টির মুনাফা বিপণন মডেল প্রতিষ্ঠার প্রতিশ্রুতিবদ্ধ। সর্বশেষতম পি 2 পি ব্লকচেইন প্রযুক্তির সংমিশ্রণ করে, দ্রুত ইউএসডিটি (টিআরসি 20, ইআরসি 20) এর মাধ্যমে গ্রাহক এবং বণিকদের সংযুক্ত করুন। নিবন্ধিত ব্যবহারকারীরা অর্ডার কমিশন পেতে পারেন, অন্যদিকে বণিকরা স্টোরের বিক্রয় ডেটা বাড়িয়ে তুলতে পারে। এটি ইন্টারনেট ব্লকচেইন মডেলের সর্বশেষ লাভের মডেল!",
    "bz8": "২. কীভাবে অন্টিকটোকশপ কাজ করবেন?",
    "bz9": "টিকটোকশপের দৈনিক প্রতিক্রিয়ার মাধ্যমে, যে পণ্যগুলি টিকটোকশপ বিক্রয়গুলিতে উন্নত করা দরকার তা কেবল অর্ডার সাবস্ক্রিপশন তৈরি করতে সহজেই অর্ডারটিতে ক্লিক করতে হবে। ব্যবহারকারীরা ব্লকচেইন ইউএসডিটির মাধ্যমে অর্ডার পরিমাণ প্রদান করে এবং প্রতিদিনের কমিশন পান।",
    "bz10": "৩. দুটি লেনদেনের ক্ষেত্রে একই মুদ্রার দামের মধ্যে কেন পার্থক্য রয়েছে?",
    "bz11": "মুদ্রার স্থানান্তর গতি, নেটওয়ার্ক শর্তাদি, মুদ্রা অ্যাক্সেসের সীমাবদ্ধতা, বিভিন্ন অঞ্চলের লোক এবং এমনকি এক্সচেঞ্জের দ্বারা সরবরাহিত ব্যবসায়ের ধরণ এবং লেনদেন সহ মুদ্রার স্বাধীনতার পক্ষে উপযুক্ত নয় এমন সমস্ত কারণগুলির কারণে দামের পার্থক্য দেখা দেয়। অতএব, একই মুদ্রা বিভিন্ন লেনদেনের ক্ষেত্রে দামের পার্থক্য সৃষ্টি করতে পারে।",
    "bz12": "৪. বিনিয়োগের লাভ?",
    "bz13": "আপনি যত বেশি দাম পাবেন, আপনি লাভ তত বেশি। একই সময়ে, টিকটোকশপ এলোমেলোভাবে বড় কমিশনের আদেশ বরাদ্দ করে।",
    "bz14": "প্রতিদিন, সিস্টেমটি স্বয়ংক্রিয়ভাবে ব্যবহারকারীরা 60 টি পণ্য অর্ডার উত্পন্ন করে এবং বিতরণ করে। বড় কমিশনের আদেশগুলি এলোমেলোভাবে বিতরণ করা হয়।",
    "bz15": "60 টি আদেশের পরে ইউএসডিটি প্রত্যাহার করা যেতে পারে। (যদি 60 টি অর্ডার শেষ না করা হয় তবে সিস্টেমটি স্বয়ংক্রিয়ভাবে দিনের উদ্বৃত্ত ক্রমের পরিমাণ পর্যন্ত বন্ধ হয়ে যাবে)",
    "bz16": "প্রতিটি অ্যাকাউন্ট কেবল একটি ইউএসডিটি অ্যাকাউন্টে আবদ্ধ হতে পারে, দয়া করে গ্রাহক পরিষেবায় যোগাযোগ করুন।",
    "bz17": "ডেইলি অর্ডার ডেলিভারি শেষ করার পরে, আপনি প্রত্যাহার শুরু করার পরে, আপনি 24 ঘন্টার মধ্যে অ্যাকাউন্টটি পাবেন এবং প্রত্যাহারের ক্ষেত্রে কোনও বিধিনিষেধ নেই।",
    "bz18": "রিচার্জ করার আগে, দয়া করে আপনার প্রত্যাহার ঠিকানাটি (টিআরসি -20 সমর্থন করুন) ইউএসডিটি ঠিকানাটি আবদ্ধ করুন।",
  "bz19": "মিউচুয়াল রিচার্জ বা ব্যাংক স্থানান্তরের মতো নিষিদ্ধ আচরণ রয়েছে এবং আচরণটি দূষিতভাবে দখল করার জন্য সন্দেহ করা হয়, এবং এটি পাওয়া যায় যে অ্যাকাউন্ট নম্বর দ্বারা আচরণটি শাস্তি দেওয়া হবে।"

  },
  "order": {
    "incomplete": "পূর্বাবস্থায় ফিরুন",
    "completed": "সম্পূর্ণ",
    "orderNumber": "অর্ডার নম্বর",
    "transactionTime": "লেনদেনের সময়",
    "price": "পরিমাণ",
    "expectedReturns": "প্রত্যাশিত ফেরত",
    "submit": "অর্ডার জমা"
  },
  "trading": {
    "accountBalance": "হিসাবের পরিমান",
    "introduce": "লেনদেনের ভূমিকা",
    "freeze": "সম্পূর্ণ সংখ্যা",
    "walletBalance": "মোট কাজ",
    "startMatching": "ম্যাচিং শুরু করুন",
    "yield": "আজকের সুবিধা",
    "orderNumber": "অর্ডার নম্বর",
    "transactionTime": "লেনদেনের সময়",
    "price": "পরিমাণ",
    "expectedReturns": "প্রত্যাশিত ফেরত",
    "startTrading": "ট্রেডিং শুরু করুন",
    "cancel": "বাতিল",
    "ts": "অপর্যাপ্ত ভারসাম্য, এখনও দরিদ্র",
    "msg": "টিকটোকশপ টিকটোকশপ থেকে প্রতিক্রিয়া ব্যবহার করে প্রতিদিন টিকটোকশপ থেকে প্রতিক্রিয়া ব্যবহার করে এমন পণ্যগুলি প্রদর্শন করতে যা টিকটোকশপ বিক্রয়ের জন্য উন্নত করা দরকার। যদি ব্যবহারকারীর কেবল অর্ডারটি ক্লিক করতে হয় তবে সিস্টেমটি স্বয়ংক্রিয়ভাবে একটি অর্ডার স্বাক্ষর তৈরি করবে। ব্যবহারকারী ইউএসডিটি ব্লকচেইনের মাধ্যমে অর্ডার পরিমাণ প্রদান করে এবং প্রতিবার কমিশনের 0.6%এরও বেশি পেতে পারে এবং সিস্টেমটি এলোমেলোভাবে বড় পুরষ্কারের আদেশগুলি বিতরণ করে।",
    "ts2": "ইঙ্গিত",
    "ts3": "অপর্যাপ্ত টাকা",
    "ts4": "রিচার্জে যান",
    "ts5": "বাতিল"
  },
  "income": {
    "totalRevenue": "ভারসাম্য",
    "residualIncome": "কমিশন",
    "claim": "গ্রহণ",
    "moduleTitle": "আমার দল",
    "headcount": "মোট মানুষ",
    "totalRecharge": "মোট রিচার্জ",
    "grade": "ব্যবহারকারী",
    "numberOfPeople": "অবদান (পরিমাণ)",
    "income": "অবস্থা",
    "viewDetails": "বিস্তারিত দেখুন",
    "task_num": "পূর্বাবস্থায় ফিরুন",
    "task_num2": "সম্পূর্ণ"
  },
  "revenueDetails": {
    "totalRevenue": "মোট রাজস্ব",
    "headcount": "মোট মানুষ",
    "totalRecharge": "মোট রিচার্জ",
    "userLevel1": "ব্যবহারকারী স্তর 1",
    "userLevel2": "ব্যবহারকারী স্তর 2",
    "userLevel3": "ব্যবহারকারী স্তর 3",
    "Nickname": "নিক নাম",
    "phone": "ফোন নম্বর",
    "Recharge": "শীর্ষে",
    "income": "আয়",
    "date": "নিবন্ধের সময়"
  },
  "user": {
    "Myaccount": "আমার অ্যাকাউন্ট",
    "Withdrawal": "প্রত্যাহার",
    "Recharge": "শীর্ষে",
    "InviteFriend": "আপনার বন্ধুদের আমন্ত্রণ করুন",
    "InviteFriendTxt": "বন্ধুদের দ্বারা অর্জিত প্রতিটি লাভের জন্য, আপনি সংশ্লিষ্ট অনুপাত কমিশন পাবেন",
    "InviteFriendBtn": "এখন আমন্ত্রণ",
    "MyInformation": "ব্যক্তিগত তথ্য",
    "FundsDetails": "মূলধন বিশদ",
    "RechargeRecord": "রিচার্জ রেকর্ড",
    "WithdrawalRecord": "প্রত্যাহার রেকর্ড",
    "Language": "ভাষা",
    "Logout": "সাইন আউট"
  },
  "Withdrawal": {
    "address": "প্রত্যাহারের ঠিকানা",
    "placeholder1": "প্রত্যাহারের ঠিকানা",
    "availableBalance": "পর্যাপ্ত টাকা",
    "placeholder2": "তোলা টাকার পরিমান",
    "actualBalance": "আসলে",
    "placeholder3": "আসলে",
    "password": "পাসওয়ার্ড",
    "placeholder4": "দয়া করে 6-16-বিট পাসওয়ার্ড লিখুন",
    "submit": "জমা দিন",
    "amount": "উপলব্ধ পরিমাণের চেয়ে বড় না",
    "w1": "হস্তান্তর পারিশ্রমিক",
    "ts1": "প্রত্যাহারের ঠিকানা পান দয়া করে",
    "ts2": "প্রত্যাহারের পরিমাণ প্রবেশ করুন"
  },
  "Recharge": {
    "qrcode": "স্ক্যান কোড রিচার্জ",
    "copy": "অনুলিপি করতে ক্লিক করুন",
    "cotakecarepy": "দ্রষ্টব্য, সম্পর্কিত বিবেচনা হ'ল আপনার স্ক্রিন বা পৃষ্ঠা রিয়েল এস্টেট ব্যবহার করা",
    "ts1": "রিচার্জ পরিমাণ",
    "ts2": "অন্যান্য পরিমাণ",
    "ts3": "নিশ্চিত",
    "ts4": "প্রথমে ওয়ালেটটি সংযুক্ত করুন",
    "ts5": "রিচার্জের পরিমাণ নির্বাচন করুন",
    "ts6": "রিচার্জে ..."
  },
  "Invitefriends": {
    "inviteCode": "আমন্ত্রণ কোড",
    "inviteTxt": "বন্ধুদের আমন্ত্রণ জানান, প্রচুর পরিমাণে আয় উপভোগ করুন, দয়া করে আমন্ত্রণ করুন",
    "copy": "অনুলিপি করতে ক্লিক করুন",
    "Rebaterules": "ছাড়",
    "RebaterulesMsg": "নিম্নলিখিত শর্তগুলি অবশ্যই পূরণ করতে হবে",
    "scale": "কমিশনের অনুপাতের বিধিগুলি নিম্নরূপ",
    "Userlevel": "ব্যবহারকারী স্তর",
    "Rules": "নিয়ম",
    "Commissionratio": "কমিশন হার",
    "ps": "কমিশন ইউএসডিটি -র সাথে নিষ্পত্তি হয়, যা যে কোনও সময় আপনার ওয়ালেটে প্রত্যাহার করা যেতে পারে"
  },
  "personalinformation": {
    "Nickname": "নিক নাম",
    "phone": "অ্যাকাউন্ট",
    "Loginpassword": "প্রবেশের গুপ্তসংকেত",
    "address": "প্রত্যাহারের ঠিকানা",
    "modify": "সংশোধন",
    "Confirm": "নিশ্চিত"
  },
  "fundDetails": {
    "amount": "পরিমাণ",
    "revenue": "আয়",
    "time": "সময়",
    "withdrawal": "প্রত্যাহার",
    "recharge": "শীর্ষে",
    "type": {
    "1": "শীর্ষে",
    "2": "প্রত্যাহার",
    "3": "কমিশন সংগ্রহ",
    "4": "আয়",
    "5": "প্রত্যাহার",
    "6": "বিচারাধীন",
    "7": "সম্পূর্ণ",
    "8": "প্রত্যাখ্যাত"
  }
  },
  "language": {
    "placeholder": "আন্তর্জাতিক অঞ্চল কোড প্রবেশ করুন",
    "Confirm": "নিশ্চিত",
    "cancel": "বাতিল"
  },
  "request": {
    "loadingText": "লোড হচ্ছে ...",
    "Serverexception": "সার্ভার অস্বাভাবিকতা"
  },
  "newLang": { "a1": "ভারসাম্য দরিদ্র", "a2": "বর্তমান টাস্ক নম্বর","a3": "প্রথমে বাইন্ডিং প্রত্যাহারের ঠিকানায় যান","a4": "অপর্যাপ্ত টাকা", "a5": "রিচার্জ করতে হবে কিনা" },
  "newLang2": {
    "a1": "বাঁধাইয়ের ঠিকানার জন্য, সংশোধন করতে দয়া করে গ্রাহক পরিষেবার সাথে যোগাযোগ করুন",
    "a2": "রিচার্জ করা দরকার",
    "a33": "পাসওয়ার্ডটি সংশোধন করতে দয়া করে গ্রাহক পরিষেবার সাথে যোগাযোগ করুন",
    "a3": "পাসওয়ার্ডটি সংশোধন করতে দয়া করে গ্রাহক পরিষেবার সাথে যোগাযোগ করুন"
    
  }
}
