export default {
  "logo": { "name": "TIK Tok", "msg": "Centrum zamówień Tiktok", "wz": "URL:" },
  "head": { "Language": "Pools" },
  "menu": {
    "home": "pierwsza strona",
    "order": "Zamówienie",
    "trading": "handel",
    "earnings": "zespół",
    "mine": "użytkownik"
  },
  "back": {
    "appDownload": "Pobierz aplikację",
    "help": "pomoc",
    "order": "Zamówienie",
    "trading": "handel",
    "income": "zespół",
    "revenueDetails": "Szczegół",
    "Withdrawal": "wycofać",
    "Recharge": "uzupełniać",
    "Invitefriends": "zaprosić przyjaciół",
    "personalinformation": "informacje osobiste",
    "gywm": "o nas",
    "kf": "obsługa klienta"
  },
  "loading": {
    "loadingText": "Ładowanie...",
    "finishedText": "Już nie",
    "loadingText2": "Dopasowanie ...",
    "loadingLogin": "Logowanie...",
    "loadingSign": "Rejestr ...",
    "confirmOrder": "w transakcji ...",
    "ldg1": "W wycofaniu ..."
  },
  "Toast": { "copy": "Replikacja", "ts": "Wybierz język" },
  "login": {
    "title": "Cześć",
    "msg": "Zaloguj się na swoje konto",
    "phone": "konto",
    "phoneCode": "Kod regionowy",
    "password": "hasło",
    "remember": "Zapamiętaj mnie",
    "forget": "Zapomnij o hasło?",
    "loginBtn": "Zaloguj sie",
    "noAccount": "Brak konta?",
    "goRegister": "Rejestr",
    "lg1": "Inne logowanie konta"
  },
  "sign": {
    "phone": "Numer telefonu",
    "phoneCode": "Kod regionowy",
    "Nickname": "Przezwisko",
    "code": "Kod weryfikacyjny",
    "sendCode": "Wyślij kod weryfikacji",
    "password": "hasło",
    "confirmPassword": "Potwierdź hasło",
    "recommendationCode": "Kod rekomendacji",
    "protocol": "Rejestracja oznacza, że ​​zgadzasz się z naszymi warunkami",
    "registerBtn": "rejestr",
    "Existing": "Istniejące konto?",
    "goLogin": "Zaloguj sie",
    "rule": {
      "phone": "Konto nie może być puste, format konta jest nieprawidłowy",
      "nickname": "Pseudonim należy wypełnić",
      "password": "Hasło nie może być puste, hasło to minimalne 6 cyfr, a hasło jest niespójne.",
      "code": "Kod weryfikacji musi być wypełniony",
      "isAgree": "Najpierw sprawdź zgodę"
    }
  },
  "home": {
    "cumulativeIncome": "Dochód skumulowany",
    "notification": "notyfikować",
    "moduleTitle1": "Moja usługa",
    "moduleTitle2": "Partner",
    "title": "notyfikować"
  },
  "download": {
    "TIKTOKSHOP": "Sklep Tiktok",
    "AndroidDownload": "Pobierz Android",
    "iOSDownload": "Apple Pobierz"
  },
  "help": {
    "bz2": "powszechny problem",
    "bz3": "Zasady handlowe",
    "bz4": "Jak wypłacić pieniądze",
    "bz5": "Jak naładować",
    "bz6": "1. Co IstikTokshop?",
    "bz7": "Tiktokshop to firma zajmująca się promocją marketingu, która pomaga sprzedawcom sklepów internetowych Tiktokshop z całego świata w celu uzyskania większej sprzedaży zamówień i zwiększeniu danych przeglądania ze sklepów internetowych Tiktokshop. Jesteśmy zobowiązani do ustanowienia trójstronnego modelu marketingu zysków między Tiktokshop a kupcami i konsumentami. Łącząc najnowszą technologię Blockchain P2P, szybko połącz konsumentów i kupców za pośrednictwem USDT (TRC20, ERC20). Zarejestrowani użytkownicy mogą uzyskać prowizję zamówień, podczas gdy kupcy mogą zwiększyć dane sprzedaży sklepu. Jest to najnowszy model zysku w internetowym modelu blockchain!",
    "bz8": "2. Jak pracować Ontiktokshop?",
    "bz9": "Poprzez codzienne informacje zwrotne Tiktokshop należy ulepszyć produkty, które należy ulepszyć podczas sprzedaży Tiktokshop. Użytkownicy płacą kwotę zamówienia za pośrednictwem Blockchain USDT i otrzymują codzienne prowizje.",
    "bz10": "3. Dlaczego istnieje różnica w cenie tej samej waluty w dwóch transakcjach?",
    "bz11": "Różnice cen są spowodowane wszystkimi czynnikami, które nie sprzyjają swobody walut, w tym szybkości transferu walut, warunkami sieciowymi, ograniczeniami dostępu walutowego, ludźmi w różnych regionach, a nawet typów handlowych i transakcji dostarczanych przez giełdę. Dlatego ta sama waluta może powodować różnice cen w różnych transakcjach.",
    "bz12": "4. Zysk inwestycyjny?",
    "bz13": "Im wyższa cena otrzymasz, tym wyższy zysk. W tym samym czasie Tiktokshop losowo przydziela duże zamówienia komisji.",
    "bz14": "Każdego dnia system automatycznie generuje i rozpowszechnia 60 zamówień towarowych użytkownikom. Duże zamówienia prowizji są losowo rozdzielane.",
    "bz15": "USDT można wycofać po 60 zamówieniach. (Jeśli 60 zamówień nie zostanie ukończonych, system automatycznie zatrzyma się do czasu nadwyżki zamówienia dnia)",
    "bz16": "Każde konto może być związane z kontem USDT.",
    "bz17": "Po ukończeniu codziennej dostawy zamówienia możesz normalnie wycofać się.",
    "bz18": "Przed naładowaniem powiązania adresu wycofania (wsparcie TRC-20)",
  "bz19": "Istnieją zachowania zakazujące, takie jak wzajemne ładowanie lub transfery bankowe, a transfer wymiany między powiązanymi kontami."

  },
  "order": {
    "incomplete": "nie spełniony",
    "completed": "zakończony",
    "orderNumber": "numer zamówienia",
    "transactionTime": "Godzina transakcji",
    "price": "Kwota",
    "expectedReturns": "Spodziewany powrót",
    "submit": "Prześlij zamówienie"
  },
  "trading": {
    "accountBalance": "Bilans konta",
    "introduce": "Wprowadzenie transakcji",
    "freeze": "Zakończony numer",
    "walletBalance": "Całkowite zadanie",
    "startMatching": "Zacznij dopasowywać",
    "yield": "Dzisiejsze korzyści",
    "orderNumber": "numer zamówienia",
    "transactionTime": "Godzina transakcji",
    "price": "Kwota",
    "expectedReturns": "Spodziewany powrót",
    "startTrading": "Zacznij handlować",
    "cancel": "Anulować",
    "ts": "Niewystarczająca równowaga, wciąż biedna",
    "msg": "Tiktokshop codziennie wykorzystuje informacje zwrotne z Tiktokshop do wyświetlania produktów, które należy ulepszyć w celu sprzedaży Tiktokshop. Jeśli użytkownik musi tylko kliknąć zamówienie, system automatycznie wygeneruje podpis zamówienia. Użytkownik płaci kwotę zamówienia za pośrednictwem Blockchain USDT i może za każdym razem uzyskać więcej niż 0,6%prowizji, a system losowo dystrybuuje duże zamówienia na nagrody.",
    "ts2": "wskazówka",
    "ts3": "Niewystarczająca ilość środków",
    "ts4": "Idź do ładowania",
    "ts5": "Anulować"
  },
  "income": {
    "totalRevenue": "Balansować",
    "residualIncome": "zamawiać",
    "claim": "odbierać",
    "moduleTitle": "moja drużyna",
    "headcount": "ogółem ludzie",
    "totalRecharge": "Całkowite ładowanie",
    "grade": "użytkownik",
    "numberOfPeople": "Wkład (ilość)",
    "income": "państwo",
    "viewDetails": "Pokaż szczegóły",
    "task_num": "nie spełniony",
    "task_num2": "zakończony"
  },
  "revenueDetails": {
    "totalRevenue": "Całkowity dochód",
    "headcount": "ogółem ludzie",
    "totalRecharge": "Całkowite ładowanie",
    "userLevel1": "Poziom użytkownika 1",
    "userLevel2": "Poziom użytkownika 2",
    "userLevel3": "Poziom użytkownika 3",
    "Nickname": "Przezwisko",
    "phone": "Numer telefonu",
    "Recharge": "uzupełniać",
    "income": "dochód",
    "date": "Czas rejestracji"
  },
  "user": {
    "Myaccount": "Moje konto",
    "Withdrawal": "wycofać",
    "Recharge": "uzupełniać",
    "InviteFriend": "Zaproś swoich znajomych",
    "InviteFriendTxt": "Za każdy zysk uzyskany przez przyjaciół otrzymasz odpowiednią komisję proporcji",
    "InviteFriendBtn": "Zaproś teraz",
    "MyInformation": "informacje osobiste",
    "FundsDetails": "Szczegóły kapitałowe",
    "RechargeRecord": "Rekord doładowania",
    "WithdrawalRecord": "Rekord wypłat",
    "Language": "język",
    "Logout": "Wyloguj się"
  },
  "Withdrawal": {
    "address": "Adres wycofania",
    "placeholder1": "Adres wycofania",
    "availableBalance": "Dostępne saldo",
    "placeholder2": "Kwota wypłaty",
    "actualBalance": "Faktycznie",
    "placeholder3": "Faktycznie",
    "password": "hasło",
    "placeholder4": "Wprowadź hasło 6-16-bitowe",
    "submit": "składać",
    "amount": "Nie większe niż dostępne kwoty",
    "w1": "Opłata manipulacyjna",
    "ts1": "Uzyskaj adres wycofania",
    "ts2": "Wprowadź kwotę wypłaty"
  },
  "Recharge": {
    "qrcode": "Naładowanie kodu skanowania",
    "copy": "Kliknij, aby skopiować",
    "cotakecarepy": "Uwaga: powiązane rozważanie jest użycie ekranu lub nieruchomości strony",
    "ts1": "Naładowanie kwoty",
    "ts2": "Inna ilość",
    "ts3": "Jasne",
    "ts4": "Najpierw podłącz portfel",
    "ts5": "Wybierz kwotę ładowania",
    "ts6": "W ładowaniu ..."
  },
  "Invitefriends": {
    "inviteCode": "Kod zaproszenia",
    "inviteTxt": "Zaproś znajomych, ciesz się dużą ilością dochodów, zapraszaj",
    "copy": "Kliknij, aby skopiować",
    "Rebaterules": "Przylga",
    "RebaterulesMsg": "Należy spełnić następujące warunki",
    "scale": "Wskaźnik zasad prowizji są następujące",
    "Userlevel": "poziom użytkownika",
    "Rules": "reguła",
    "Commissionratio": "Stawka prowizji",
    "ps": "Komisja jest rozstrzygnięta z USDT, którą można wycofać do twojego portfela w dowolnym momencie"
  },
  "personalinformation": {
    "Nickname": "Przezwisko",
    "phone": "konto",
    "Loginpassword": "Login Hasło",
    "address": "Adres wycofania",
    "modify": "Poprawiać",
    "Confirm": "Jasne"
  },
  "fundDetails": {
    "amount": "Kwota",
    "revenue": "dochód",
    "time": "czas",
    "withdrawal": "wycofać",
    "recharge": "uzupełniać",
    "type": {
    "1": "uzupełniać",
    "2": "wycofać",
    "3": "Kolekcja Komisji",
    "4": "dochód",
    "5": "Wycofać",
    "6": "Aż do",
    "7": "zakończony",
    "8": "odrzucony"
  }
  },
  "language": {
    "placeholder": "Wprowadź kod międzynarodowego obszaru",
    "Confirm": "Jasne",
    "cancel": "Anulować"
  },
  "request": {
    "loadingText": "Ładowanie...",
    "Serverexception": "Nieprawidłowości serwera"
  },
  "newLang": { "a1": "Równowaga jest słaba", "a2": "Obecny numer zadania","a3": "Najpierw przejdź do adresu wycofania wiążącego","a4": "Niewystarczająca ilość środków", "a5": "Czy naładować" },
  "newLang2": {
    "a1": "W przypadku adresu wiązania prosimy o kontakt z obsługą klienta, aby zmodyfikować",
    "a2": "Musisz naładować",
    "a3": "Skontaktuj się z obsługą klienta, aby zmodyfikować hasło",
     "a33":"Póngase"
  }
}
