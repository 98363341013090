export default {
  "logo": { "name": "Tiktok", "msg": "Tiktok Sipariş Merkezi", "wz": "URL:" },
  "head": { "Language": "Türkçe" },
  "menu": {
    "home": "ön Sayfa",
    "order": "Emir",
    "trading": "ticaret",
    "earnings": "takım",
    "mine": "kullanıcı"
  },
  "back": {
    "appDownload": "Uygulama İndir",
    "help": "yardım",
    "order": "Emir",
    "trading": "ticaret",
    "income": "takım",
    "revenueDetails": "Detay",
    "Withdrawal": "geri çekilmek",
    "Recharge": "doldurmak",
    "Invitefriends": "arkadaşları davet etmek",
    "personalinformation": "kişisel bilgi",
    "gywm": "Hakkımızda",
    "kf": "müşteri servisi"
  },
  "loading": {
    "loadingText": "Yükleniyor...",
    "finishedText": "Daha fazla yok",
    "loadingText2": "Eşleştirme ...",
    "loadingLogin": "giriş...",
    "loadingSign": "Kayıt olmak ...",
    "confirmOrder": "İşlemde ...",
    "ldg1": "Çekilmede ..."
  },
  "Toast": { "copy": "Çoğaltma", "ts": "Lütfen Dil'i seçin" },
  "login": {
    "title": "Merhaba",
    "msg": "Hesabınıza giriş yapın",
    "phone": "hesap",
    "phoneCode": "Bölge kodu",
    "password": "şifre",
    "remember": "Beni hatırla",
    "forget": "Şifreyi unutmak mı?",
    "loginBtn": "Giriş yapmak",
    "noAccount": "Hesap yok?",
    "goRegister": "Kayıt olmak",
    "lg1": "Diğer Hesap Giriş"
  },
  "sign": {
    "phone": "Telefon numarası",
    "phoneCode": "Bölge kodu",
    "Nickname": "Takma ad",
    "code": "Doğrulama kodu",
    "sendCode": "Doğrulama Kodunu Gönderin",
    "password": "şifre",
    "confirmPassword": "Şifreyi Onayla",
    "recommendationCode": "Tavsiye Kodu",
    "protocol": "Kayıt, şartlar ve koşullarımızı kabul ettiğiniz anlamına gelir",
    "registerBtn": "kayıt olmak",
    "Existing": "Mevcut hesap?",
    "goLogin": "Giriş yapmak",
    "rule": {
      "phone": "Hesap boş olamaz, hesap biçimi yanlış",
      "nickname": "Takma ad doldurulmalı",
      "password": "Parola boş olamaz, parola minimal 6 basamaktır ve şifre tutarsızdır.",
      "code": "Doğrulama kodu doldurulmalı",
      "isAgree": "Lütfen önce rızayı kontrol edin"
    }
  },
  "home": {
    "cumulativeIncome": "Kümülatif gelir",
    "notification": "bilgilendirmek",
    "moduleTitle1": "Hizmetim",
    "moduleTitle2": "Ortak",
    "title": "bilgilendirmek"
  },
  "download": {
    "TIKTOKSHOP": "Tiktok Mağazası",
    "AndroidDownload": "Android İndir",
    "iOSDownload": "Apple İndir"
  },
  "help": {
    "bz2": "ortak sorun",
    "bz3": "Ticaret Kuralları",
    "bz4": "Para Nasıl Çekilir",
    "bz5": "Nasıl Şarj Yapılır",
    "bz6": "1. Ne istiktokshop?",
    "bz7": "Tiktokshop, Tiktokshop çevrimiçi mağaza tüccarlarının dünyanın her yerinden daha fazla sipariş satış almasına ve Tiktokshop çevrimiçi mağazalarından görüntüleme verilerini artırmasına yardımcı olan bir pazarlama tanıtım şirketidir. Tiktokshop ile tüccarlar ve tüketiciler arasında üç partili bir kar pazarlama modeli kurmaya kararlıyız. En son P2P blockchain teknolojisini birleştirerek, tüketicileri ve tüccarları USDT (TRC20, ERC20) aracılığıyla hızlı bir şekilde bağlayın. Kayıtlı kullanıcılar sipariş komisyonunu alabilirken, tüccarlar mağazanın satış verilerini artırabilir. İnternet blockchain modelindeki en son kar modeli!",
    "bz8": "2. Ontiktokshop nasıl çalışılır?",
    "bz9": "Tiktokshop'un günlük geri bildirimleri sayesinde, Tiktokshop satışlarında geliştirilmesi gereken ürünlerin sadece sipariş abonelikleri oluşturmak için siparişi kolayca tıklaması gerekir. Kullanıcılar sipariş tutarını blockchain USDT üzerinden öder ve günlük komisyonlar alırlar.",
    "bz10": "3. İki işlemde aynı para biriminin fiyatında neden bir fark var?",
    "bz11": "Fiyat farklılıkları, para aktarımı hızı, ağ koşulları, para erişim kısıtlamaları, farklı bölgelerdeki kişiler ve hatta Borsa tarafından sağlanan işlem türleri de dahil olmak üzere para özgürlüğüne elverişli olmayan tüm faktörlerden kaynaklanmaktadır. Bu nedenle, aynı para birimi farklı işlemlerde fiyat farklılıklarına neden olabilir.",
    "bz12": "4. Yatırım karı?",
    "bz13": "Aldığınız fiyat ne kadar yüksek olursa, kar elde edersiniz. Aynı zamanda Tiktokshop, büyük komisyon emirlerini rastgele tahsis eder.",
    "bz14": "Her gün sistem, kullanıcılara 60 emtia siparişini otomatik olarak üretir ve dağıtır. Büyük komisyon siparişleri rastgele dağıtılır.",
    "bz15": "USDT 60 siparişten sonra geri çekilebilir. (60 sipariş tamamlanmazsa, sistem günün fazla sipariş miktarına kadar otomatik olarak durur)",
    "bz16": "Her hesap bir USDT hesabına bağlanabilir.",
    "bz17": "Günlük sipariş teslimatını tamamladıktan sonra, geri çekilmeye başladıktan sonra hesabı 24 saat içinde alacaksınız.",
    "bz18": "Şarjdan önce, lütfen çekme adresinizi (TRC-20'yi destekleyin) USDT adresini tıklatabilir ve şarj için kullandığınız blockchain'i (TRC-20) seçebilirsiniz.",
  "bz19": "Karşılıklı şarj veya banka transferleri gibi yasaklanmış davranışlar ve ilgili hesaplar arasında değişim transferleri vardır ve davranışın hesap numarası tarafından cezalandırılacağı bulunmuştur."

  },
  "order": {
    "incomplete": "yapılmamış",
    "completed": "tamamlanmış",
    "orderNumber": "sipariş numarası",
    "transactionTime": "işlem saati",
    "price": "Miktar",
    "expectedReturns": "Beklenen Geri Dönüş",
    "submit": "Siparişi Gönder"
  },
  "trading": {
    "accountBalance": "Hesap bakiyesi",
    "introduce": "İşlem Giriş",
    "freeze": "Tamamlanmış numara",
    "walletBalance": "Toplam görev",
    "startMatching": "Eşleşmeye Başla",
    "yield": "Bugünün Faydaları",
    "orderNumber": "sipariş numarası",
    "transactionTime": "işlem saati",
    "price": "Miktar",
    "expectedReturns": "Beklenen Geri Dönüş",
    "startTrading": "Ticarete başla",
    "cancel": "İptal etmek",
    "ts": "Yetersiz denge, hala fakir",
    "msg": "Tiktokshop, Tiktokshop satışları için geliştirilmesi gereken ürünleri görüntülemek için her gün Tiktokshop'tan geri bildirim kullanıyor. Kullanıcının yalnızca siparişi tıklaması gerekiyorsa, sistem otomatik olarak bir sipariş imzası oluşturur. Kullanıcı Sipariş Tutarını USDT Blockchain üzerinden öder ve her seferinde komisyonun%0,6'sından fazlasını alabilir ve sistem büyük ödül siparişlerini rastgele dağıtır.",
    "ts2": "ipucu",
    "ts3": "Yetersiz bakiye",
    "ts4": "Şarj etmek",
    "ts5": "İptal etmek"
  },
  "income": {
    "totalRevenue": "Denge",
    "residualIncome": "komisyon",
    "claim": "almak",
    "moduleTitle": "benim takımım",
    "headcount": "Toplam İnsanlar",
    "totalRecharge": "Toplam Şarj",
    "grade": "kullanıcı",
    "numberOfPeople": "Katkı (Miktar)",
    "income": "durum",
    "viewDetails": "Detayları göster",
    "task_num": "yapılmamış",
    "task_num2": "tamamlanmış"
  },
  "revenueDetails": {
    "totalRevenue": "Toplam gelir",
    "headcount": "Toplam İnsanlar",
    "totalRecharge": "Toplam Şarj",
    "userLevel1": "Kullanıcı Seviyesi 1",
    "userLevel2": "Kullanıcı Seviyesi 2",
    "userLevel3": "Kullanıcı Seviyesi 3",
    "Nickname": "Takma ad",
    "phone": "Telefon numarası",
    "Recharge": "doldurmak",
    "income": "gelir",
    "date": "Kayıt süresi"
  },
  "user": {
    "Myaccount": "hesabım",
    "Withdrawal": "geri çekilmek",
    "Recharge": "doldurmak",
    "InviteFriend": "Arkadaşlarını davet et",
    "InviteFriendTxt": "Arkadaşlar tarafından kazanılan her kâr için, ilgili oran komisyonunu alacaksınız",
    "InviteFriendBtn": "Şimdi davet edin",
    "MyInformation": "kişisel bilgi",
    "FundsDetails": "Sermaye detayları",
    "RechargeRecord": "Yeniden Şarj Kayıt",
    "WithdrawalRecord": "Para çekme kaydı",
    "Language": "dil",
    "Logout": "oturumu Kapat"
  },
  "Withdrawal": {
    "address": "Para çekme adresi",
    "placeholder1": "Para çekme adresi",
    "availableBalance": "Kalan bakiye",
    "placeholder2": "Para çekme miktarı",
    "actualBalance": "Aslında",
    "placeholder3": "Aslında",
    "password": "şifre",
    "placeholder4": "Lütfen 6-16 bit şifreyi girin",
    "submit": "göndermek",
    "amount": "Mevcut tutarlardan daha fazla olmayın",
    "w1": "Hizmet bedeli",
    "ts1": "Lütfen para çekme adresini alın",
    "ts2": "Lütfen para çekme miktarını girin"
  },
  "Recharge": {
    "qrcode": "Kodu Tarama Şarjı",
    "copy": "Kopyala için tıklayın",
    "cotakecarepy": "Not, ilgili bir husus ekranınızı veya sayfanızı gayrimenkul kullanmaktır",
    "ts1": "Şarj tutarı",
    "ts2": "Diğer miktar",
    "ts3": "Elbette",
    "ts4": "Lütfen önce cüzdanı bağlayın",
    "ts5": "Lütfen şarj tutarını seçin",
    "ts6": "Şarjda ..."
  },
  "Invitefriends": {
    "inviteCode": "Davet Kodu",
    "inviteTxt": "Arkadaşları davet edin, büyük miktarda gelirin tadını çıkarın, lütfen davet edin",
    "copy": "Kopyala için tıklayın",
    "Rebaterules": "İndirim",
    "RebaterulesMsg": "Aşağıdaki koşullar yerine getirilmelidir",
    "scale": "Komisyon oranı kuralları aşağıdaki gibidir",
    "Userlevel": "Kullanıcı düzeyi",
    "Rules": "kural",
    "Commissionratio": "komisyon oranı",
    "ps": "Komisyon, herhangi bir zamanda cüzdanınıza geri çekilebilen USDT ile yerleşmiştir."
  },
  "personalinformation": {
    "Nickname": "Takma ad",
    "phone": "hesap",
    "Loginpassword": "giriş şifresi",
    "address": "Para çekme adresi",
    "modify": "Tekrar düzeltme yapmak",
    "Confirm": "Elbette"
  },
  "fundDetails": {
    "amount": "Miktar",
    "revenue": "gelir",
    "time": "zaman",
    "withdrawal": "geri çekilmek",
    "recharge": "doldurmak",
    "type": {
      "1": "doldurmak",
      "2": "geri çekilmek",
      "3": "Komisyon toplama",
      "4": "gelir",
      "5": "Geri çekilmek",
      "6": "Askıda olması",
      "7": "tamamlanmış",
      "8": "Reddedilmiş"
    }
  },
  "language": {
    "placeholder": "Lütfen Uluslararası Alan Kodunu girin",
    "Confirm": "Elbette",
    "cancel": "İptal etmek"
  },
  "request": {
    "loadingText": "Yükleniyor...",
    "Serverexception": "Sunucu Anormallikleri"
  },
  "newLang": { "a1": "Denge zayıf", "a2": "Mevcut Görev Numarası","a3": "Lütfen önce bağlayıcı para çekme adresine gidin","a4": "Yetersiz bakiye", "a5": "Şarj olup olmayacağı" },
  "newLang2": {
    "a1": "Bağlayıcı adresi için lütfen değiştirmek için Müşteri Hizmetleri ile iletişime geçin.",
    "a2": "Şarj edilmesi gerekiyor",
    "a3": "Şifreyi değiştirmek için lütfen müşteri hizmetleriyle iletişime geçin",
     "a33":"Póngase"
  }
}
